(function ($) {
  $(window).load(function () {
    setTimeout(() => {
      if ($(window).width() < 768) {
        return;
      }

      if (
        (-1 !== document.location.search.indexOf('form[sent]') && -1 !== document.location.search.indexOf('form%5Bsent%5D'))
        || -1 === document.location.hash.indexOf('#form')
      ) {
        return;
      }

      var $stickyHeader = $('.header');
      var $message = $('.form_success_message');

      if (!$stickyHeader.length || !$message.length) {
        return;
      }

      var currentScrollTop = $('html').scrollTop();
      var newScrollTop = $message.offset().top - $stickyHeader.outerHeight() - 25;

      if (newScrollTop >= currentScrollTop) {
        return;
      }

      $("html, body").animate({ scrollTop: $message.offset().top - $stickyHeader.outerHeight() - 25 });
    }, 500);

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3,
          margin: 30
        }
      }
    });
  });

  $(document).ready(function () {

    // sticky
    var windowWidth = $(window).width();
    if (windowWidth > 1200) {
      $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
          $('body').addClass('sticky');
          $('.header').addClass('sticky');
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

    // navbar-toggler
    if (document.documentElement.classList.contains('desktop')) {

      const toggler = document.querySelector('.navbar-toggler');
      const menu = document.getElementById('default_menu');

      toggler.addEventListener('mouseenter', () => {
        menu.classList.add('show');
      });
    }

  });
})(jQuery);
